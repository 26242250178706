import React from "react";
import { DangerButton } from "best-common-react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { AuthConstants } from "../../constants/AuthConstants";
import { RouteConstants } from "../../constants/RouteConstants";
import bottomBars from "../../assets/login/bottom-bars.png";
import bottomBars2 from "../../assets/login/bottom-bars@2x.png";
import bottomBars3 from "../../assets/login/bottom-bars@3x.png";
import piaLogo from "../../assets/login/pia-logo@2x.png";
import playerStats from "../../assets/login/players-stats.png";
import playerStats2 from "../../assets/login/players-stats@2x.png";
import playerStats3 from "../../assets/login/players-stats@3x.png";
import "../../styles/okta-login-overrides.css";

const LoginRoot = styled.div`
  top: 0px;
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, #02132b, #183256);
`;

const LoginFlex = styled.div.attrs({
  className: "d-flex justify-content-between align-items-stretch"
})`
  width: 100%;
  height: 100%;
`;

const LoginWrapper = styled.div.attrs({
  className: "d-flex flex-column"
})`
  flex: 0 0 18%;
  background-color: ${props => props.theme["dark-blue"]};
  border-right: solid 3px #39465e;
`;

const LoginWidgetContainer = styled.div.attrs({
  className: "d-flex flex-column justify-content-center"
})`
  height: 100%;
`;

const LoginWidget = styled.div.attrs({
  id: "sign-in-widget"
})`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme["dark-blue"]};
  align-items: center;
`;

const LoginLogo = styled.img.attrs({
  className: "d-flex",
  src: piaLogo
})`
  width: 100%;
  padding: 3rem;
`;

const LoginAccent = styled.div`
  height: 10px;
  background-color: ${props => props.theme["mlb-red"]};
`;

const LoginBG = styled.div.attrs({
  className: "d-flex flex-column-reverse align-items-end"
})`
  flex: 0 0 82%;
  width: 100%;
  height: 100%;
`;

const LoginBars = styled.img.attrs({
  className: "d-flex",
  src: bottomBars,
  srcset: bottomBars2 + ", " + bottomBars3
})`
  object-fit: contain;
  mix-blend-mode: overlay;
  width: 100%;
`;

const LoginPlayers = styled.img.attrs({
  className: "d-flex",
  src: playerStats,
  srcset: playerStats2 + ", " + playerStats3
})`
  object-fit: contain;
  width: 95%;
`;

class Login extends React.Component {
  constructor(props) {
    let added = false;
    super(props);

    document.title = "PIA Plus";
  }

  componentDidMount() {
    const { trackingHelper } = this.props.rootStore;

    trackingHelper.trackPageView(RouteConstants.LOGIN.title);
  }

  render() {
    return (
      <LoginRoot>
        <LoginFlex>
          <LoginWrapper>
            <LoginWidgetContainer>
              <LoginWidget>
                <LoginLogo />
                <DangerButton
                  onClick={() => {
                    let { authStore, trackingHelper } = this.props.rootStore;
                    trackingHelper.track("Login Click");
                    authStore.login();
                  }}
                  style={{ width: "max-content" }}
                >
                  Log in with Okta
                </DangerButton>
              </LoginWidget>
            </LoginWidgetContainer>
            <LoginAccent />
          </LoginWrapper>
          <LoginBG>
            <LoginBars />
            <LoginPlayers />
          </LoginBG>
        </LoginFlex>
      </LoginRoot>
    );
  }
  static propTypes = {
    rootStore: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
  };
}

export default inject("rootStore")(withTheme(observer(Login)));
